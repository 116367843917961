// import React, { useState, useEffect } from "react";
// import {
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   TableBody,
//   IconButton,
//   Tooltip,
//   Modal,
//   Box,
//   Typography,
//   Button,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";

// const TeamLeaderHierarchy = () => {
//   const [hasError, setHasError] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [leaveSummaryDetails, setLeaveSummaryDetails] = useState([]);
//   const [openModal, setOpenModal] = useState(false);
//   const [selectedEmployee, setSelectedEmployee] = useState(null);
//   const [teamLeader1, setTeamLeader1] = useState("");
//   const [teamLeader2, setTeamLeader2] = useState("");
//   useEffect(() => {
//     const fetchLeaveSummaryDetails = async () => {
//       setIsLoading(true);
//       try {
//         const response = await fetch(`${process.env.REACT_APP_APIURL}leavesummaryuser`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch leave summary details");
//         }
  
//         const data = await response.json();
//         setLeaveSummaryDetails(data.leavesummaryuserDetails);
//       } catch (error) {
//         console.error("Error fetching leave summary details:", error);
//         setHasError(true);
//       } finally {
//         setIsLoading(false);
//       }
//     };
  
//     fetchLeaveSummaryDetails();
//   }, []);
  

//   const handleOpenModal = (employee) => {
//     setSelectedEmployee(employee);
//     setTeamLeader1(employee.teamLeader1 || "");
//     setTeamLeader2(employee.teamLeader2 || "");
//     setOpenModal(true);
//   };

//   const handleCloseModal = () => {
//     setOpenModal(false);
//     setSelectedEmployee(null);
//     setTeamLeader1("");
//     setTeamLeader2("");
//   };

//   const handleSave = async () => {
//     if (!selectedEmployee) return;
  
//     try {
//       const response = await fetch(`${process.env.REACT_APP_APIURL}updateTeamLeaders`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           U_id: selectedEmployee.U_id, // Include U_id
//           U_name: selectedEmployee.U_name,
//           teamLeader1,
//           teamLeader2,
//         }),
//       });
  
//       if (!response.ok) {
//         throw new Error("Failed to update team leaders");
//       }
  
//       const data = await response.json();
//       console.log("Success:", data);
  
//       // Update state for immediate UI feedback
//       const updatedLeaveSummaryDetails = leaveSummaryDetails.map((row) =>
//         row.U_id === selectedEmployee.U_id
//           ? { ...row, teamLeader1, teamLeader2 }
//           : row
//       );
  
//       setLeaveSummaryDetails(updatedLeaveSummaryDetails);
//       handleCloseModal();
//     } catch (error) {
//       console.error("Error updating team leaders:", error);
//     }
//   };
  
  
//   return (
//     <>
//       <Table>
//         <TableHead>
//           <TableRow style={{ backgroundColor: "#1a237e", color: "white" }}>
//             <TableCell style={{ color: "white" }}>Employee Name</TableCell>
//             <TableCell style={{ color: "white" }}>Team Leader 1</TableCell>
//             <TableCell style={{ color: "white" }}>Team Leader 2</TableCell>
//             <TableCell style={{ color: "white" }}>Actions</TableCell>
//           </TableRow>


          
//         </TableHead>
//         <TableBody>
//           {leaveSummaryDetails.map((row, index) => (
//             <TableRow key={index}>
//               <TableCell>{row.U_name}</TableCell>
//               <TableCell>{row.teamLeader1 || "-"}</TableCell>
//               <TableCell>{row.teamLeader2 || "-"}</TableCell>
//               <TableCell>
//                 <Tooltip title={row.teamLeader1 || row.teamLeader2 ? "Edit" : "Add"}>
//                   <IconButton onClick={() => handleOpenModal(row)}>
//                     {row.teamLeader1 || row.teamLeader2 ? (
//                       <EditIcon color="primary" />
//                     ) : (
//                       <AddIcon color="secondary" />
//                     )}
//                   </IconButton>
//                 </Tooltip>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>

//       {/* Modal */}
//       <Modal open={openModal} onClose={handleCloseModal}>
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: 400,
//             bgcolor: "background.paper",
//             border: "2px solid #000",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//           }}
//         >
//           <Typography variant="h6" mb={2}>
//             {selectedEmployee ? `Select Team Leaders for ${selectedEmployee.U_name}` : `Select Team Leaders for ${selectedEmployee?.U_name}`}
//           </Typography>
//           <Typography variant="body1" mb={1}>
//             Team Leader 1
//           </Typography>
//           <Select
//             fullWidth
//             value={teamLeader1}
//             onChange={(e) => setTeamLeader1(e.target.value)}
//           >
//             <MenuItem value="TL1">Dummy TL1</MenuItem>
//             <MenuItem value="TL2">Dummy TL2</MenuItem>
//           </Select>
//           <Typography variant="body1" mt={2} mb={1}>
//             Team Leader 2
//           </Typography>
//           <Select
//             fullWidth
//             value={teamLeader2}
//             onChange={(e) => setTeamLeader2(e.target.value)}
//           >
//             <MenuItem value="TL3">Dummy TL3</MenuItem>
//             <MenuItem value="TL4">Dummy TL4</MenuItem>
//           </Select>
//           <Button
//             variant="contained"
//             color="primary"
//             fullWidth
//             sx={{ mt: 3 }}
//             onClick={handleSave}
//           >
//             Save
//           </Button>
//         </Box>
//       </Modal>
//     </>
//   );
// };

// export default TeamLeaderHierarchy;




import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Modal,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { Edit as EditIcon, Add as AddIcon } from "@mui/icons-material";

const TeamLeaderHierarchy = () => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leaveSummaryDetails, setLeaveSummaryDetails] = useState([]);
  const [teamLeaders, setTeamLeaders] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [teamLeader1, setTeamLeader1] = useState("");
  const [teamLeader2, setTeamLeader2] = useState("");

  useEffect(() => {
    const fetchLeaveSummaryDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_APIURL}leavesummaryuser`);
        if (!response.ok) {
          throw new Error("Failed to fetch leave summary details");
        }

        const data = await response.json();
        setLeaveSummaryDetails(data.leavesummaryuserDetails);
      } catch (error) {
        console.error("Error fetching leave summary details:", error);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTeamLeaders = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_APIURL}teamleader`);
        if (!response.ok) {
          throw new Error("Failed to fetch team leaders");
        }

        const data = await response.json();
        setTeamLeaders(data.teamleaderDetails);
      } catch (error) {
        console.error("Error fetching team leaders:", error);
      }
    };

    fetchLeaveSummaryDetails();
    fetchTeamLeaders();
  }, []);

  const handleOpenModal = (employee) => {
    setSelectedEmployee(employee);
    setTeamLeader1(employee.teamLeader1 || "");
    setTeamLeader2(employee.teamLeader2 || "");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedEmployee(null);
    setTeamLeader1("");
    setTeamLeader2("");
  };

  const handleSave = async () => {
    if (!selectedEmployee) return;

    const selectedTL1 = teamLeaders.find((tl) => tl.TL_id === teamLeader1);
    const selectedTL2 = teamLeaders.find((tl) => tl.TL_id === teamLeader2);

    try {
      const response = await fetch(`${process.env.REACT_APP_APIURL}updateTeamLeaders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          U_id: selectedEmployee.U_id,
          U_name: selectedEmployee.U_name,
          teamLeader1: selectedTL1 ? { Name: selectedTL1.Name, TL_id: selectedTL1.TL_id, Designation: selectedTL1.Designation } : null,
          teamLeader2: selectedTL2 ? { Name: selectedTL2.Name, TL_id: selectedTL2.TL_id, Designation: selectedTL2.Designation } : null,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update team leaders");
      }

      const data = await response.json();
      console.log("Success:", data);

      const updatedLeaveSummaryDetails = leaveSummaryDetails.map((row) =>
        row.U_id === selectedEmployee.U_id
          ? { ...row, teamLeader1: selectedTL1?.Name, teamLeader2: selectedTL2?.Name }
          : row
      );

      setLeaveSummaryDetails(updatedLeaveSummaryDetails);
      handleCloseModal();
    } catch (error) {
      console.error("Error updating team leaders:", error);
    }
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow style={{ backgroundColor: "#1a237e", color: "white" }}>
            <TableCell style={{ color: "white" }}>Employee Name</TableCell>
            <TableCell style={{ color: "white" }}>Team Leader 1</TableCell>
            <TableCell style={{ color: "white" }}>Team Leader 2</TableCell>
            <TableCell style={{ color: "white" }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaveSummaryDetails.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.U_name}</TableCell>
              <TableCell>{row.teamLeader1 || "-"}</TableCell>
              <TableCell>{row.teamLeader2 || "-"}</TableCell>
              <TableCell>
                <Tooltip title={row.teamLeader1 || row.teamLeader2 ? "Edit" : "Add"}>
                  <IconButton onClick={() => handleOpenModal(row)}>
                    {row.teamLeader1 || row.teamLeader2 ? (
                      <EditIcon color="primary" />
                    ) : (
                      <AddIcon color="secondary" />
                    )}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            {selectedEmployee ? `Select Team Leaders for ${selectedEmployee.U_name}` : ""}
          </Typography>
          <Typography variant="body1" mb={1}>
            Team Leader 1
          </Typography>
          <Select
  fullWidth
  value={teamLeader1}
  onChange={(e) => setTeamLeader1(e.target.value)}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 48 * 5 + 8, // 5 items * item height (48px) + padding (8px)
        overflowY: "auto",
      },
    },
  }}
>
  {teamLeaders
    .filter((tl) => tl.Name !== selectedEmployee?.U_name)
    .map((tl) => (
      <MenuItem key={tl.TL_id} value={tl.TL_id}>
        {tl.Name}
      </MenuItem>
    ))}
</Select>

          <Typography variant="body1" mt={2} mb={1}>
            Team Leader 2
          </Typography>
          <Select
  fullWidth
  value={teamLeader2}
  onChange={(e) => setTeamLeader2(e.target.value)}
  MenuProps={{
    PaperProps: {
      style: {
        maxHeight: 48 * 5 + 8, // Limit dropdown to 5 items
        overflowY: "auto",
      },
    },
  }}
>
  {teamLeaders
    .filter((tl) => tl.Name !== selectedEmployee?.U_name)
    .map((tl) => (
      <MenuItem key={tl.TL_id} value={tl.TL_id}>
        {tl.Name}
      </MenuItem>
    ))}
</Select>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default TeamLeaderHierarchy;
